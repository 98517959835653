import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import Brand from "../../../assets/branding/amcaLogo.png";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";
import { useCookies } from "react-cookie";
import LogoutModal from "../../organisms/modals/LogoutModal";

import Home from "../../icons/Home";
import Business from "../../icons/Business";

import Configuration from "../../icons/Configuration";
import Report from "../../icons/Report";

const Navbar = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["un", "role"]);
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ||
      (path === "/dashboard/home" && location.pathname === "/dashboard/home")
      ? styles.active_nav
      : "";
  };

  // Log the filtered routes to verify
  // console.log("filtered-routes", filteredRoutes);

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <nav className={styles.navbar}>
        <div
          className={styles.icon}
          onClick={() => navigate("/dashboard/survey")}
        >
          <img src={Brand} height="55px" alt="Logo" loading="eager" />
        </div>

        <ul className={` ${styles.navMenu} ${isActive ? styles.active : ""}`}>
          {/* <li onClick={removeActive}>
            <NavLink
              to={"/dashboard/home"}
              className={`${styles.navLink} ${isLinkActive(`/dashboard/home`)}`}
            >
              <Home /> Home
            </NavLink>
          </li> */}
          <li onClick={removeActive}>
            <NavLink
              to={"/dashboard/survey"}
              className={`${styles.navLink} ${isLinkActive(
                `/dashboard/survey`,
              )}`}
            >
              <Business />
              Survey
            </NavLink>
          </li>
          {atob(cookies.role) === "Admin" && (
            <li onClick={removeActive}>
              <NavLink
                to={"/dashboard/setting"}
                className={`${styles.navLink} ${isLinkActive(
                  `/dashboard/setting`,
                )}`}
              >
                <Configuration />
                Settings
              </NavLink>
            </li>
          )}
        </ul>

        <div className={styles.left_side}>
          <div onClick={handleMenuOpen(setProfileAnchorEl)}>
            <CircleAvatar name={cookies.un ? atob(cookies.un) : null} />
          </div>

          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            onClick={toggleActiveClass}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>
        </div>

        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose(setProfileAnchorEl)}
          PaperProps={{
            style: {
              width: "200px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          {/* <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile");
              setProfileAnchorEl(null);
            }}
          >
            Profile
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile/change-password");
              setProfileAnchorEl(null);
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setProfileAnchorEl(null);
            }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose(setMenuAnchorEl)}
          PaperProps={{
            style: {
              width: "220px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          {["resource", "library", "faqs", "taxonomy", "settings"].map(
            (menu) => (
              <MenuItem
                key={menu}
                onClick={() => {
                  navigate(`/dashboard/${menu}`);
                  setMenuAnchorEl(null);
                }}
              >
                {menu.charAt(0).toUpperCase() + menu.slice(1)}
              </MenuItem>
            ),
          )}
        </Menu>
      </nav>
    </div>
  );
};

export default Navbar;
