import { useState } from "react";
import { NavigationData } from "../../../../../helpers/staticData";
import SideSearchBar from "../../../../molecules/SideSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ViewPage from "../../../../organisms/template/ViewPage";
import BackBtn from "../../../../atoms/BackBtn";
import style from "./index.module.scss";

const ViewSurvey = () => {
  const [cookies] = useCookies(["role", "t"]);
  const [searchQuery, setSearchQuery] = useState();
  const [navigationClicked, SetNavigationClicked] = useState();
  const [testDetails, setTestDetails] = useState({});
  const [testsNavigation, setTestNavigation] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className={`mb-3 ${style.backbuttonStyle}`}>
          <BackBtn
            handleClick={() => {
              navigate("/dashboard/survey");
            }}
          />
        </div>
        <div style={{ height: "2px" }}></div>
        <div className="sidesearchpageWrap">
          <div className="searchBar">
            <SideSearchBar
              viewClassName={"viewClassName"}
              NavigationData={NavigationData}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              SetNavigationClicked={SetNavigationClicked}
              testDetails={testDetails}
              setTestNavigation={setTestNavigation}
              testsNavigation={testsNavigation}
            />
          </div>
          <div>
            <ViewPage
              roleType={
                location?.state === "Surveyor" ? "Surveyor" : atob(cookies.role)
              }
              testDetails={testDetails}
              setTestDetails={setTestDetails}
              navigationClicked={navigationClicked}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewSurvey;
