import React from "react";

const TypeDiv = ({ data, given_color, classes, style }) => {
  // Define a function to map data values to colors
  const getColor = (data) => {
    let trimmedData;

    if (typeof data === "string") {
      trimmedData = data.replace(/\s/g, "");
    } else {
      trimmedData = data;
    }

    switch (trimmedData) {
      case "Submitted":
        return "#B5E4CA";
      case "Created":
        return "#FFEEAD";
      case "Ongoing":
        return "#75D4FE";
      default:
        return "#FFFFFF"; // Default color, you can change this to your preferred default color
    }
  };

  const color = given_color || getColor(data);

  let styles = {
    backgroundColor: color,
    ...style,
  };

  return (
    <div className={`type-div ${classes}`} style={styles}>
      <span>
        {typeof data === "boolean" ? (data ? "Active" : "Inactive") : data}
      </span>
    </div>
  );
};

export default TypeDiv;
