import { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../atoms/CustomButton";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Search from "../../../molecules/search";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../organisms/modals/Modal";
import Form from "./AddSurvey/addSurvey";
import Pagination from "../../../molecules/Pagination";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { getData, patchData, postData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import NoContentIcon from "../../../icons/NocontentIcon";
import TagInput from "../../../atoms/TagInput";
import { Menu, MenuItem } from "@mui/material";
import InfoCard from "../../../atoms/InfoCard";
import Business from "../../../icons/Business";
import AdminIcon from "../../../icons/admin";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { convertDateToDDMMYYYY } from "../../../../helpers/formatDate";
import InputField from "../../../atoms/InputField";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import { surveyStatus } from "../../../../helpers/staticData";
import TypeDiv from "../../../atoms/StatusDiv/TypeDiv";
import { capitalizeWords } from "../../../../helpers/capitalize";
import { DateSelector } from "../../../molecules/MonthYearSelector";

const Survey = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "role"]);

  const roleType = atob(cookies.role);
  const screenWidth = useScreenWidth();
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });
  const [surveyModalState, setSurveyModalState] = useState({
    isModalOpen: false,
    modalData: null,
    modalId: "",
    modalTitle: "",
    modalSubTitle: "",
  });
  const [shareSurveyModalState, setShareSurveyModalState] = useState({
    isModalOpen: false,
    modalData: null,
    modalId: "",
    modalTitle: "",
  });
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [shareEmail, setShareEmail] = useState();
  const [linkExpiryDate, setLinkExpiryDate] = useState();
  const [linkError, setLinkError] = useState("");
  const [emailError, setEmailError] = useState(null); // State to store error message
  const [shareSurveyData, setShareSurveyData] = useState();
  const [infoCardData, setInfoCardData] = useState();
  const [filters, setFilters] = useState({});
  const [addedByDataList, setAddedByDataList] = useState();
  const [clientDataList, setClientDataList] = useState();
  const [shareSurveyId, setShareSurveyId] = useState();
  const [shareShareDate, setShareSurveyDate] = useState({
    date: new Date(),
  });

  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emails.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(", ")}`);
    } else {
      setEmailError(null);
    }

    return emails.filter((email) => emailRegex.test(email));
  };

  const getDashBoardInfoCardData = async () => {
    try {
      const res = await getData({
        endpoint: "getAllDashboard",
        token: cookies.t,
      });
      setInfoCardData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getAddedByData = async () => {
    try {
      const res = await getData({
        endpoint: "survey/getAddedByList",
        token: cookies.t,
      });
      if (res) {
        const uniqueEmails = new Set();

        const uniqueData = res?.data.filter((item) => {
          if (uniqueEmails.has(item.email)) {
            return false;
          }
          uniqueEmails.add(item.email);
          return true;
        });

        const formattedData = uniqueData.map((item) => ({
          label: item.name,
          value: item.name,
        }));

        setAddedByDataList(formattedData);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClinetData = async () => {
    try {
      const res = await getData({
        endpoint: "survey/getClientList",
        token: cookies.t,
      });
      if (res) {
        const uniqueKeys = new Set();

        // Step 2: Filter unique entries
        const uniqueData = res?.data.filter((item) => {
          const key = `${item.label}|${item.value}`; // Create a unique key for each object
          if (uniqueKeys.has(key)) {
            return false; // Skip duplicates
          }
          uniqueKeys.add(key);
          return true; // Include unique item
        });
        setClientDataList(uniqueData);
      }
    } catch (err) {
      console.log("errr", err);
    }
  };
  useEffect(() => {
    getAddedByData();
    getClinetData();
    getDashBoardInfoCardData();
  }, []);

  const getSurveyData = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "survey/getAllSurvey",
        params: {
          search: searchedValue,
          page: currentPage,
          limit: itemsPerPage,
          status: filters?.["Status"],
          surveyStartDate: filters?.startDate0,
          surveyEndDate: filters?.endDate0,
          createdAtStartDate: filters?.startDate1,
          createdAtEndDate: filters?.endDate1,
          client_name: filters?.["Client"],
          added_by: filters?.["Added By"],
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage, filters]);

  useEffect(() => {
    getSurveyData();
  }, [getSurveyData]);

  const totalItems = tableData?.[0]?.trialData;

  const handleEdit = (id) => {
    navigate(`/dashboard/survey/edit/${id}`);
  };
  const handleView = (id) => {
    navigate(`/dashboard/survey/view/${id}`);
  };
  const handleTable = (id) => {
    navigate(`/dashboard/company/table/${id}`);
  };
  const handleSurveyEdit = (id) => {
    navigate(`/dashboard/survey/surveyor/${id}`, { state: "Surveyor" });
  };

  const handleDelete = (id, name) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: id,
      name: name,
    }));
  };
  const handleShareSurveyData = async (id, data) => {
    try {
      const res = await getData({
        endpoint: "shareSurvey/getShareSurveyBySurveyId",
        token: cookies.t,
        params: {
          survey_id: data?._id,
        },
        data: {},
      });
      setShareSurveyData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSurveyShare = async (id, data) => {
    try {
      const res = await getData({
        endpoint: "shareSurvey/getShareSurveyBySurveyId",
        token: cookies.t,
        params: {
          survey_id: data?._id,
        },
        data: {},
      });
      setShareEmail(res?.data?.emailDetail?.map((item) => item?.email));
      setLinkExpiryDate(res?.data?.link_expire);
      setShareSurveyId(res?.data?._id);
      if (res?.data?.createdAt) {
        setShareSurveyDate(() => ({
          date: res?.data?.createdAt,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
    setSurveyModalState(() => ({
      isModalOpen: true,
      modalData: data,
      modalId: id,
      modalSubTitle: "Add / modify surveyors",
      modalTitle: `Survey Name:- ${data?.survey_name}`,
    }));
  };
  console.log("surveyModalState", surveyModalState);

  const handleSurveyShareClose = () => {
    setSurveyModalState(() => ({
      isModalOpen: false,
      modalData: null,
      modalId: "",
      modalTitle: "",
      modalSubTitle: "",
    }));
    setShareEmail("");
  };

  const handleShareSurveyClose = () => {
    setShareSurveyModalState(() => ({
      isModalOpen: false,
      modalTitle: "",
      modalData: "",
      modalId: "",
    }));
  };

  const handleDeleteConfirm = async (id) => {
    try {
      // Add your delete logic here, for example:
      const res = await patchData({
        endpoint: `survey/updateSurvey`,
        token: cookies.t,
        params: {
          id: id,
        },
        data: {
          status: "Archive",
        },
      });
      if (res?.data) {
        toast.success("Survey Deleted Successfully", {
          style: confimationStyles,
          duration: 3000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
      // Refresh the data after deletion
      getSurveyData();
    } catch (error) {
      console.error(error);
    } finally {
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      if (action === "editSurvey") {
        handleEdit(selectedRow._id);
      } else if (action === "surveyor") {
        handleSurveyEdit(selectedRow._id);
      } else if (action === "ShareSurvey") {
        handleSurveyShare(selectedRow._id, selectedRow);
      } else if (action === "delete") {
        handleDelete(selectedRow._id, selectedRow?.survey_name);
      } else if (action === "sharedWith") {
        handleShareSurveyData(selectedRow._id, selectedRow);
        setShareSurveyModalState(() => ({
          isModalOpen: true,
          modalTitle: "Survey Share With",
          modalData: selectedRow,
          modalId: selectedRow?._id,
        }));
      } else if (action === "view") {
        handleView(selectedRow._id);
      }
    }
    handleMenuClose();
  };

  const columns = [
    {
      field: "survey_name",
      headerName: "Survey Name",
      ...(screenWidth < 550 ? { width: 200 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.survey_name
              ? capitalizeWords(params?.row?.survey_name)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      ...(screenWidth < 550 ? { width: 200 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.added_by?.[0]?.name
              ? capitalizeWords(params?.row?.added_by?.[0]?.name)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      ...(screenWidth < 550 ? { width: 200 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.client_name
              ? capitalizeWords(params?.row?.client_name)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Survey Date",
      ...(screenWidth < 550 ? { width: 150 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.date
              ? convertDateToDDMMYYYY(params?.row?.date)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      ...(screenWidth < 550 ? { width: 150 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.createdAt
              ? convertDateToDDMMYYYY(params?.row?.createdAt)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      ...(screenWidth < 550 ? { width: 150 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div>
            <TypeDiv data={params?.row?.status} />
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      ...(screenWidth < 550 ? { width: 150 } : { flex: 1 }),
      // sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span
              onClick={(event) => handleMenuOpen(event, params.row)}
              className="me-2 pointer"
            >
              <GridMoreVertIcon />
            </span>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleMenuClose}
            >
              {(roleType === "Admin" &&
                (params?.row?.status === "Created" ||
                  params?.row?.status === "Ongoing" ||
                  params?.row?.status === "Submitted")) ||
              (roleType === "Manager" &&
                (params?.row?.status === "Created" ||
                  params?.row?.status === "Ongoing")) ? (
                <MenuItem onClick={() => handleMenuItemClick("editSurvey")}>
                  Edit Survey
                </MenuItem>
              ) : null}

              <MenuItem onClick={() => handleMenuItemClick("view")}>
                View Survey
              </MenuItem>

              {(roleType === "Admin" &&
                (params?.row?.status === "Created" ||
                  params?.row?.status === "Ongoing" ||
                  params?.row?.status === "Submitted")) ||
              (roleType === "Manager" &&
                (params?.row?.status === "Created" ||
                  params?.row?.status === "Ongoing")) ? (
                <MenuItem onClick={() => handleMenuItemClick("surveyor")}>
                  Surveyor Mode
                </MenuItem>
              ) : null}
              {(params?.row?.status === "Created" ||
                params?.row?.status === "Ongoing") && (
                <>
                  {(roleType === "Admin" || roleType === "Manager") && (
                    <MenuItem
                      onClick={() => handleMenuItemClick("ShareSurvey")}
                    >
                      Share Survey
                    </MenuItem>
                  )}
                </>
              )}

              {(roleType === "Admin" || roleType === "Manager") && (
                <MenuItem onClick={() => handleMenuItemClick("delete")}>
                  Delete Survey
                </MenuItem>
              )}

              {(roleType === "Admin" ||
                roleType === "Manager" ||
                roleType === "Viewer" ||
                roleType === "Surveyor") && (
                <MenuItem onClick={() => handleMenuItemClick("sharedWith")}>
                  Shared list
                </MenuItem>
              )}
            </Menu>
          </>
        );
      },
    },
  ];

  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };
  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleShareSurvey = async (shareEmail) => {
    const dataToSend = {
      survey_name: surveyModalState?.modalData?.survey_name,
      survey_id: surveyModalState?.modalData?._id,
      emailDetail: shareEmail?.map((item) => {
        return {
          email: item,
        };
      }),
      link_expire: linkExpiryDate,
    };
    try {
      const res = await postData({
        endpoint: "shareSurvey/createShareSurvey",
        token: cookies.t,
        params: {},
        data: dataToSend,
      });
      if (res) {
        toast.success("Survey shared Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
      setSurveyModalState(() => ({
        isModalOpen: false,
        modalData: null,
        modalTitle: "",
        modalId: "",
        modalSubTitle: "",
      }));
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleUpdateShareSurvey = async (shareEmail) => {
    const dataToSend = {
      survey_name: surveyModalState?.modalData?.survey_name,
      survey_id: surveyModalState?.modalData?._id,
      emailDetail: shareEmail?.map((item) => {
        return {
          email: item,
        };
      }),
      link_expire: linkExpiryDate,
    };
    try {
      const res = await patchData({
        endpoint: "shareSurvey/updateShareSurvey",
        token: cookies.t,
        params: {
          id: shareSurveyId,
        },
        data: dataToSend,
      });
      if (res) {
        toast.success("Share survey update Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
      setSurveyModalState(() => ({
        isModalOpen: false,
        modalData: null,
        modalTitle: "",
        modalId: "",
        modalSubTitle: "",
      }));
    } catch (error) {
      console.log("err", error);
    }
  };

  const filterData = {
    Status: surveyStatus,
    Client: clientDataList,
    "Added By": addedByDataList,
  };

  return (
    <>
      <div className="header-infoCardWrap info_card_mbr">
        <div className="infoCardWrap">
          <InfoCard
            color="#B1E5FC"
            title="Total Surveys"
            data={infoCardData ? infoCardData?.surveyData : 0}
            svgIcon={<Business />}
            isSvg
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#B1E5FC"
            title="Total Users"
            data={infoCardData ? infoCardData?.userData : 0}
            svgIcon={<AdminIcon />}
            isSvg
            // data={data?.total_company || 0}
          />
        </div>
        <div className="survery-btn-display-header-mbr">
          <PageHeader title="Survey list" type="small" />
          {atob(cookies.role) === "Admin" && (
            <div className="d-flex gap-3">
              <CustomButton
                type="btn-primary"
                text="Add Survey"
                handleClick={() => navigate("add")}
                classes={"survery-btn-display-mbr"}
              />
            </div>
          )}
        </div>
      </div>
      <PageCard>
        <div className="d-flex space-between gap-3-mbr">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <div className="filter-add">
            <FilterComponent
              filterData={filterData}
              isDateRange
              dateRangeNames={["Survey Date", "Create Date"]}
              onFilterChange={(filter) => {
                setFilters(filter);
              }}
            />
            {atob(cookies.role) === "Admin" && (
              <div className="d-flex gap-3">
                <CustomButton
                  type="btn-primary"
                  text="Add Survey"
                  handleClick={() => navigate("add")}
                  classes={"survery-btn-none-mbr"}
                />
              </div>
            )}
          </div>
        </div>

        {tableData?.[0]?.trialData && tableData?.[0]?.trialData?.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              rows={tableData?.[0]?.trialData || []}
              columns={columns}
              tHeight="50vh"
            />

            {tableData?.[0]?.pageDetails?.count > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={tableData?.[0]?.pageDetails?.count}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
      </PageCard>

      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: "visible",
            cssText: "overflow: visible !important",
          }}
          width="600px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === "Edit Location" ? modalState.modalData : null
            }
            // refetchData={getUserData}
          />
        </Modal>
      )}
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          // height="300px"
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Survey"
          onClick={() => handleDeleteConfirm(modalState.modalId)}
        />
      )}
      {surveyModalState?.isModalOpen && (
        <Modal
          isActive={surveyModalState.isModalOpen}
          title={surveyModalState?.modalTitle}
          modalSubTitle={surveyModalState.modalSubTitle}
          onClose={handleSurveyShareClose}
          width="600px"
        >
          <>
            <TagInput
              type="text"
              name="Email"
              customholder="Enter Email"
              InputHeight="small"
              placeholder="Enter Email"
              onChange={(tags) => {
                const validEmails = validateEmails(tags);
                setShareEmail(validEmails);
              }}
              selectedTags={shareEmail}
              error={emailError}
            />
            {/* <div className="mt-3">
              <InputField
                customholder="Enter validity in days"
                onChange={(value) => {
                  if (/^\d*$/.test(value)) {
                    setLinkExpiryDate(value);
                    setLinkError(""); // Clear any previous error when input is valid
                  } else {
                    setLinkError("Please enter numbers only."); // Set error message
                  }
                }}
                value={linkExpiryDate}
              />
              {linkError && (
                <div className="error-input-message">{linkError}</div>
              )}
            </div> */}
            <div
              style={{ position: "relative", width: "100%" }}
              className="mt-3"
            >
              <DateSelector
                placeholder="Valid till"
                onChange={async (option) => {
                  setLinkExpiryDate(option);
                }}
                disablePast
                selectedDate={linkExpiryDate}
              />
            </div>
            <div className="shareSarveyStyle">
              * The validity begins on the day it is initially shared.{" "}
              <span>
                Share Date:-{" "}
                <strong>{convertDateToDDMMYYYY(shareShareDate?.date)}</strong>
              </span>
            </div>
            <div className="ShareButtonWrap">
              {shareSurveyId ? (
                <>
                  <CustomButton
                    text="Update Share"
                    iconRequired={false}
                    type="btn-primary"
                    size="btn-lg"
                    handleClick={() => {
                      if (emailError || linkExpiryDate === undefined) {
                        toast.error("Please enter valid value", {
                          duration: 1000,
                          style: errorStyles,
                        });
                      } else {
                        handleUpdateShareSurvey(shareEmail);
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <CustomButton
                    text="Share"
                    iconRequired={false}
                    type="btn-primary"
                    size="btn-lg"
                    handleClick={() => {
                      if (emailError || linkExpiryDate === undefined) {
                        toast.error("Please enter valid value", {
                          duration: 1000,
                          style: errorStyles,
                        });
                      } else {
                        handleShareSurvey(shareEmail);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </>
        </Modal>
      )}
      {shareSurveyModalState?.isModalOpen && (
        <Modal
          isActive={shareSurveyModalState.isModalOpen}
          title={shareSurveyModalState?.modalTitle}
          onClose={handleShareSurveyClose}
          width="600px"
        >
          {shareSurveyData ? (
            <>
              <div>
                <div className="row">
                  <div className="col-3">
                    <label>Survey Name</label>
                  </div>
                  <div className="col-3">
                    <label>{shareSurveyData?.survey_name}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label>Email</label>
                  </div>
                  <div className="col-3">
                    {shareSurveyData?.emailDetail?.map((item) => {
                      return <label className="">{item?.email}</label>;
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <strong>This survey has not shared yet </strong>
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
};
export default Survey;
