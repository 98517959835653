import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuthenticated } from "../hooks/useAuthenticated";

const GuestGuard = ({ children }) => {
  const auth = useAuthenticated();

  if (auth) {
    return <Navigate to="/dashboard/survey" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
